.nautile-app {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 4rem;
}

@media (max-width: 430px) {
  .nautile-app {
    margin-top: 0rem;    
  }

  body {
    background-color: #f4d2cd;
  }
}

.logo {
  width: 20rem;
}

.header {
  text-align: center;
  margin-top: 1.5rem;
}



.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f4d2cd;
  border-radius: 1rem;
  width: 35rem;
  padding: 1em;
}

@media (max-width: 430px) {
  .form-wrapper {
    border-radius: 0rem;
  }
}


@media (max-width: 430px) {
  .form-wrapper {
    width: 100%;
    height: max-content;
  }
}

.title {
  font-size: 1.5rem;
  margin-top: 1.2rem;
  margin-bottom: 0.8rem;
  text-align: center;
}

.form-body .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  max-width: 90%;
}

.form fieldset {
  display: flex;
  border: none;
  text-align: center;

}

.form fieldset div {
  padding-right: 3.1rem;
}

.form fieldset div:last-child {
  padding-right: 0
}

.form .field-wrapper input,
.form .button-wrapper input {
  font-family: inherit;
  font-size: 1.2rem;
  margin: 0.5em 0;
  width: 100%;
  border: #ffffff 2px solid;
  border-radius: 3px;
}

.form .field-wrapper,
.form .checkbox-wrapper,
.form .button-wrapper {
  width: 100%;
}



.form .checkbox-wrapper input[type="checkbox"] {
  appearance: none;
  background-color: #ffffff;
  margin-right: 1.3rem;
  font: inherit;
  color: currentColor;
  width: 45px;
  height: 21px;
  border: 0.12rem solid currentColor;
  border-radius: 50px;
}

@media (max-width: 430px) {
  .form .checkbox-wrapper input[type="checkbox"] {
    width: 75px;
    height: 21px;
  }
}

/*Checkbox uncheck*/
.form .checkbox-wrapper input[type="checkbox"]::before {
  content: "";
  width: 15px;
  height: 15px;
  display: flex;
  background-color: #000000;
  margin-left: 2px;
  margin-top: 2px;
  transition: 120ms margin-left ease-in-out;
  border-radius: 50px;
}

/*Checkbox check*/
.form .checkbox-wrapper input[type="checkbox"]:checked::before {
  margin-left: 50%;
  background-color: #AB2A25;
}

.form .checkbox-wrapper {
  /* width: 30rem; */
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form fieldset input[type="radio"] {
  appearance: none;
  background-color: #ffffff;
  margin: 0.5rem;
  font: inherit;
  color: currentColor;
  width: 1.2rem;
  height: 1.2rem;
  border: 0.12rem solid currentColor;
  border-radius: 50%;
}

.form fieldset input[type="radio"]::before {
  content: "";
  width: 0.85rem;
  height: 0.85rem;
  display: flex;
  background-color: #AB2A25;
  border-radius: 50%;
  margin-left: 0.1rem;
  margin-top: 0.12rem;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

.form fieldset input[type="radio"]:checked::before {
  transform: scale(1);
}

/*Rules link*/
.field-wrapper a {
  color: black;
  margin-left: 3.7rem;
}

.field-wrapper a:hover {
  text-decoration: none;
}

/*Subscription button*/
.form .button-wrapper input[type=submit], .success .button-wrapper {
  max-width: 10rem;
  border-radius: 50px;
  padding: 0.5rem;
  margin-top: 2rem;

  border: none;
  background-color: #331717;
  color: #ffffff;
}

.form .button-wrapper {
  display: flex;
  justify-content: center;
}

.success {
  font-size: 1.3rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
  text-align: center;
}

.success .button-wrapper {
  text-transform: uppercase;
  text-decoration: none;
}

.success p {
  margin-bottom: 3rem;
}
.captcha {
  margin-top: 1rem;
}

.form-body p {
  text-align: justify;
  font-size: 1.1rem;
  width: 90%;
  margin: 4rem auto 2rem auto;
  letter-spacing: 0.05rem;
  line-height: 2.2rem;
  font-weight: 200;
  
}


.form-body div {
  text-align: center;
}

.form-body button {
  background-color: #A82B2D ;
  border: 0;
  padding: 1rem;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: bold;
  border-radius: 4px;
  width: 12rem;
  margin-bottom: 1.5rem;
}